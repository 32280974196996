export interface IInterpolationMap {
  [key: string]: string;
}

/**
 * Function that dinamically replaces words between curly brackets in strings.
 * @param text i.e. 'Welcome, {{name}}'
 * @param interpolationMap i.e. {name: 'Pippo'}
 * @returns 'Welcome, Pippo'
 */

export function interpolation(
  text: string,
  interpolationMap: IInterpolationMap
): string {
  const regex = /\{{(.*?)\}}/gm; // get words contained between {{ }}
  const matches = text.match(regex) ?? [];
  return matches.reduce((acc, curr) => {
    const cleanMatch = curr.replace('{{', '').replace('}}', '');
    return acc.replace(curr, interpolationMap[cleanMatch] ?? '');
  }, text);
}
