import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { IImageWithTextDataCmp } from '../IImageWithTextCmp';
import { CardSlider } from '@/components/molecules';
import { CircularProgress, Typography } from '@/components/atoms';
import { interpolation } from '@/utils/interpolation';
import { ICategoryCard } from '@/handlers/Category';

interface ITypoContainer {
  bgColor?: string | undefined;
}

const TypoContainer = styled.div<ITypoContainer>`
  background-color: ${props => props.bgColor};
  padding: ${props =>
    props.bgColor && props.bgColor !== '#ffffff' ? '24px' : ''};
`;

const CustomTypography = styled(Typography)`
  text-align: center;
  &:nth-child(2) {
    margin-bottom: 16px;
  }
`;

const Loader = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageWithTextCarouselCustom: React.FC<IImageWithTextDataCmp> = props => {
  const {
    imageWithTextData,
    bgColor,
    headingText,
    headingDescription,
    template,
    enableFont,
  } = props;

  const user = useSelector((state: RootState) => state.userReducer);
  const customerFirstName =
    user?.customer?.firstName || user?.profiling?.firstName || 'there';
  const customerCompany =
    user?.customer?.defaultAddress?.company || user?.profiling?.shopName || '';

  const registrationPreferences = user.customer?.registrationPreferences?.value;

  let dataFilter = imageWithTextData;

  let categories: string[] = [];
  if (registrationPreferences) {
    const parsedPreferences = JSON.parse(registrationPreferences);
    if (parsedPreferences?.categories?.length > 0) {
      categories = parsedPreferences?.categories.map((cat: ICategoryCard) => {
        return `${parsedPreferences.gender.gender}/${cat.category_path}`;
      });
    }

    dataFilter = imageWithTextData.filter(image => {
      return categories.some(cat => {
        return (
          image.category?.toLowerCase() === cat.toLowerCase() ||
          !image.category ||
          cat.split('/')[0].toLowerCase() === image.category.toLowerCase()
        );
      });
    });
  }

  return (
    <>
      {!user.loadingCustomer ? (
        <>
          {headingText && (
            <TypoContainer bgColor={bgColor}>
              <CustomTypography
                component="h1"
                datatype="heading_3"
                dangerouslySetInnerHTML={{
                  __html: interpolation(headingText, {
                    customerName: `<span>${customerFirstName}</span>`,
                    storeName: `<span>${customerCompany}</span>`,
                  }),
                }}
                className={enableFont ? 'titlebig' : ''}></CustomTypography>
            </TypoContainer>
          )}
          {headingDescription && (
            <TypoContainer bgColor={bgColor}>
              <CustomTypography
                component="h2"
                datatype="heading_5"
                dangerouslySetInnerHTML={{
                  __html: interpolation(headingDescription, {
                    customerName: `<span>${customerFirstName}</span>`,
                    storeName: `<span>${customerCompany}</span>`,
                  }),
                }}
              />
            </TypoContainer>
          )}
        </>
      ) : (
        <Loader>
          <CircularProgress />
        </Loader>
      )}
      {!user.loadingCustomer && dataFilter && dataFilter.length > 0 ? (
        <CardSlider
          withScrollBar={false}
          typeCard={'custom'}
          cards={dataFilter}
          template={
            template === 'hero_custom_carousel_small' ||
            template === 'carousel_custom_small'
              ? 'carousel_small'
              : 'carousel'
          }
          backgroundColor={bgColor}
        />
      ) : null}
    </>
  );
};

export default ImageWithTextCarouselCustom;
